// src/i18n.js
import { createI18n } from 'vue-i18n';

import en from './locales/en.json';
import zh from './locales/zh.json';
import ja from './locales/ja.json';

const messages = {
  en,
  zh,
  ja,
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

export default i18n;
